<template>
    <div>
        <LoadingIcon :isLoading="isLoading" />
        <div class="px-2">
            <v-container>
                <v-row no-gutters>
                    <v-col cols="7">
                        <p class="display-1 font-weight-thin mb-2">Financiën</p>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="2" class="pl-2 year-container">
                        <RoadtripYearDropdown :year="selectedYear" @changed="onYearChanged($event)" />
                    </v-col>
                    <v-col cols="2" class="pl-2 quarter-container">
                        <RoadtripQuarterDropdown :quarter="selectedQuarter" @changed="onQuarterChanged($event)" />
                    </v-col>
                </v-row> 
                <StatisticInfoCards 
                    :title1="`Orders:`"
                    :value1="orderCount"
                    :title2="`Omzet:`"
                    :value2="revenue"
                    :value2class="`title`"
                    :title3="`Kosten:`"
                    :value3="costs"
                    :value3class="`title`"
                    />
                <v-row>
                    <v-col>
                        <div class="pb-3">{{description}}</div>
                        <v-btn color="primary" block @click="goToNewInvoice()">
                            Factuur toevoegen
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <InvoiceList />
    </div>
</template>

<script>
import LoadingIcon from '@/components/common/LoadingIcon';
import RouteMixin from "@/mixins/route.mixin";
import FormatMixin from "@/mixins/format.mixin";
import StatisticInfoCards from "@/components/admin/StatisticInfoCards";
import InvoiceList from "@/components/admin/InvoiceList";
import RoadtripYearDropdown from '@/components/common/options/RoadtripYearDropdown';
import RoadtripQuarterDropdown from '@/components/common/options/RoadtripQuarterDropdown';

export default {
    name: 'Financiën',
    components: 
    {
        LoadingIcon,
        InvoiceList,
        StatisticInfoCards,
        RoadtripYearDropdown,
        RoadtripQuarterDropdown
    },
    mixins: [RouteMixin, FormatMixin],
    data: () => ({
        orderCount: '',
        revenue: '',
        costs: '',
        selectedYear: "",
        selectedQuarter: 1,
        isLoading: false,
        description: "Hierboven zijn de belangrijkste statistieken van de huidige periode weergegeven. Daarnaast zijn alle betreffende facturen hieronder te zien Het is mogelijk om alle gegevens op deze pagina per kwartaal en per jaar te sorteren.",
    }),
    mounted(){
        this.selectedYear = `${this.getCurrentYear()}`;
        this.selectedQuarter = this.getCurrentQuarter();
        this.isMounted = true;
    },
    methods: 
    {
        onYearChanged(year)
        {
            this.selectedYear = year;
            this.getFinancialStatisticsByTimeFrame();
        },
        onQuarterChanged(quarter)
        {
            this.selectedQuarter = quarter;
            this.getFinancialStatisticsByTimeFrame();
        },
        getFinancialStatisticsByTimeFrame()
        {  
            if(this.isMounted == false || this.isMounted == undefined) return;

            this.isLoading = true;
            let request = {
                year: this.selectedYear,
                quarter: this.selectedQuarter
            }

            this.$store.dispatch('expenseModule/getFinancialStatisticsByTimeFrame', request)
            .then(data => {
                this.orderCount = `${data.orderCount}`;
                this.revenue = `${this.getCurrency(data.revenue)}`;
                this.costs = `${this.getCurrency(data.costs)}`;
                this.isLoading = false;
            })
            .catch(() => {
                this.orderCount = ``;
                this.revenue = ``;
                this.costs = ``;
                this.$store.commit('dialog/showDialog', 'Something went wrong with retrieving financial statistics.');
                this.isLoading = false;
            })
        },
    }
}
</script>
<style scoped>
.header
{
    display: inline-flex;
}

.edit-icon
{
    width: 15px;
    height: 15px;
    cursor: pointer;
}

.v-input
{
    height: 25px;
}

.sort-container button.v-btn:not(.v-btn--round).v-size--default
{
    border-bottom: 1px solid #858282 !important;
    border-radius: 0px;
}

.theme--dark.v-card.participant-card,
.participant-card .theme--dark.v-list
{
    background-color: #005E39;
}

.participant-card .v-card__title
{
    margin-bottom: -10px;
}

.v-sheet.v-card.participant-card 
{
    border-radius: 10px;
}
</style>